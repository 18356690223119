import React, { useState } from 'react';
import imageInsightLogo from '../assets/image-insight-logo.svg';
import ratingsDesign from '../assets/ratings-design.svg';
import '../styles/comingsoon.css';

const ComingSoon = () => {
  const [email, setEmail] = useState('');
  const [file, setFile] = useState(null);
  const [submittedEmails, setSubmittedEmails] = useState(() => {
    // Load submitted emails from localStorage (if available) when the component mounts
    const savedEmails = localStorage.getItem('submittedEmails');
    return savedEmails ? JSON.parse(savedEmails) : [];
  });

  const handleFileChange = (event) => {
    console.log(event)
    const fileName = event.target.files[0].name.toLowerCase();  // Get the first file selected
    if (fileName.endsWith(".png") || fileName.endsWith(".jpg") || fileName.endsWith(".jpeg")) {
      setFile(event.target.files[0]);
    } else {
      alert("Photo can only be of type JPG or PGN");
      event.target.value = '';
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!file) {
      alert("Please upload a photo");
    } else if (submittedEmails.includes(email.toLowerCase())) { // Check if the email has already been submitted
      alert("This email has already submitted a photo");
    } else {
      const updatedEmails = [...submittedEmails, email.toLowerCase()];
      setSubmittedEmails(updatedEmails);

      // Store the updated emails in localStorage
      localStorage.setItem('submittedEmails', JSON.stringify(updatedEmails));

      alert(`Successfully submitted!\n\nEmail: ${email}\nFile: ${file ? file.name : 'No file selected'}`);
    }
  };

  return (
    <div className="container">
      {/* New container for logo, title, and motto */}
      <div className="header-container">
        <img src={imageInsightLogo} alt="Logo" className="logo" />
        <h1>Image Insight</h1>
        <p className="motto">Accurate ratings for photos that work on dating apps.</p>
      </div>

      {/*<div className="ratings-container">
        <img src={ratingsDesign} alt="ratings" className="ratings" />
      </div>*/}

      
      <div className="submit-container">
      <p>Upload a photo along with your email to be inlcuding in our beta rating batch!</p>
        <form onSubmit={handleSubmit} className="form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="input"
          />
          <input
            type="file"
            onChange={handleFileChange}
            className="input"
          />
          <button type="submit" className="btn">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default ComingSoon;
