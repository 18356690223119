import logo from './logo.svg';
import ComingSoon from './components/comingsoon.js';
import './App.css';

function App() {
  return (
    <div className="App">
      <ComingSoon />  {/* Render the ComingSoon component */}
    </div>
  );
}


export default App;
